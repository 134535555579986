import React from "react";
import { BsInfoCircleFill } from "react-icons/bs";
import Header from "../../components/header/header";
import "./style.scss";

const About = () => {

    const personalDetail =
        [
            { label: "Name", value: "Erick Njenga" },
            { label: "Age", value: "18" },
            { label: "Email", value: "ericknjnega01@gmail.com" }
        ]

    const jobSummary = "Hello! I’m Erick Njenga, a passionate software developer, game developer, AI enthusiast with a love for learning and creating. My expertise lies in C++ programming, Unreal Engine, and AI development, and I’m always eager to explore new challenges and expand my knowledge. While I’m still in the early stages of showcasing my work, I’ve dedicated countless hours to building a strong foundation in game mechanics, user-focused design, or AI integrations. My projects so far have been a journey of discovery and growth, and I’m excited to share them with the world soon. When I’m not coding or experimenting with new ideas, I enjoy exploring game modding, learning emerging frameworks, or solving creative puzzles. Thank you for visiting my portfolio—stay tuned for updates as I begin sharing my work and achievements!"

    return (
        <section id="About" className="About">
            <Header HeaderText="About Me" Icon={<BsInfoCircleFill size={30} />} />
            <div className="About__Content">
                <p>
                    {jobSummary}
                </p>

                <h3 className="personalInformation">Personal Information</h3>
                <ul>
                    {personalDetail.map((item, index) => (
                        <li key={index}>
                            <span className="Title">{item.label}</span>
                            <span className="Value">{item.value}</span>
                        </li>
                    ))}
                </ul>

            </div>
        </section>
    )
}

export default About;
const particlesOptions = {
  background: {
    color: {
      //value: "#1e1e2f", // The background color of the particle area (dark theme for portfolio).
    },
  },
  fpsLimit: 60, // Limits the frames per second for the particles to improve performance.
  interactivity: {
    events: {
      onClick: { 
        enable: true, 
        mode: "push", // Adds new particles where the user clicks.
      },
      onHover: { 
        enable: true, 
        mode: "grab", // Particles form a "link" to the mouse cursor on hover.
      },
      resize: true, // Updates the particle layout when the window is resized.
    },
    modes: {
      push: {
        quantity: 6, // Number of particles added when the user clicks.
      },
      grab: {
        distance: 200, // Maximum distance for particles to "connect" to the cursor.
        line_linked: {
          opacity: 0.5, // Opacity of the particle connection lines.
        },
      },
      repulse: {
        distance: 100, // Repels particles away from the cursor (used for interactive effects).
        duration: 0.4, // Time duration for the repulse effect.
      },
    },
  },
  particles: {
    color: {
      value: ["#ff007f", "#00ffff", "#ffcc00"], // Colors of the particles (bright for portfolio style).
    },
    links: {
      color: "#ffffff", // Connection line color between particles.
      distance: 150, // Maximum distance for particles to connect with lines.
      enable: true, // Enables links between particles.
      opacity: 0.4, // Opacity of the connection lines.
      width: 1, // Thickness of the connection lines.
    },
    collisions: {
      enable: true, // Enables particle collisions for more realistic effects.
    },
    move: {
      enable: true, // Enables particle movement.
      speed: 3, // Movement speed of particles.
      direction: "none", // Movement direction of particles ("none" for random movement).
      random: false, // Particles move predictably if `false`.
      straight: false, // Particles move in straight lines if `true`.
      outModes: {
        default: "bounce", // When particles reach the boundary, they "bounce" back.
      },
    },
    number: {
      value: 50, // Initial number of particles
      density: {
        enable: true, // Enables density-based calculation for particle numbers.
        area: 800, // Area for density calculations (higher = fewer particles in smaller screens).
      },
      value: 120, // Total number of particles.
    },
    life: {
      duration: {
        value: 5, // Each particle will exist for 5 seconds
      },
      count: 1, // Number of "lifecycles" each particle goes through
    },
    opacity: {
      value: { min: 0.3, max: 0.8 }, // Randomized opacity for each particle.
    },
    shape: {
      type: ["circle", "star", "polygon"], // Different shapes of particles.
      polygon: {
        sides: 5, // Number of sides for polygonal particles.
      },
    },
    size: {
      value: { min: 1, max: 5 }, // Random size range for particles.
    },
  },
  detectRetina: true, // Ensures particles look sharp on high-DPI screens.
};

export default particlesOptions;

import "./style.scss";

const Header = ({ HeaderText, Icon }) => {
  return (
    <div className="Wrapper">
      <h2>{HeaderText}</h2>
      <span>{Icon}</span>
    </div>
  );
};

export default Header;

import React, { useState } from "react";
import { BsInfoCircleFill, BsFillPersonFill, BsFillEnvelopeFill, BsFillChatLeftTextFill } from "react-icons/bs";
import { IoMdContact } from "react-icons/io";
import Header from "../../components/header/header";
import "./style.scss";

const Contact = () => {
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        message: ""
    });
    const [isSubmitted, setIsSubmitted] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setIsSubmitted(true);
        // Handle form submission (e.g., send the data to an API)
        // Reset form after submission if needed
        setFormData({ name: "", email: "", message: "" });
    };

    return (
        <section id="Contact" className="contact-section">
            <Header HeaderText="Contact Me" Icon={<IoMdContact size={30} />} />
            <div className="contact-content">
                <form onSubmit={handleSubmit} className="contact-form">
                    <div className="form-group">
                        <label htmlFor="name"><BsFillPersonFill size={20} /></label>
                        <input
                            type="text"
                            id="name"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                            placeholder="Your full name"
                            required
                        />
                    </div>

                    <div className="form-group">
                        <label htmlFor="email"><BsFillEnvelopeFill size={20} /></label>
                        <input
                            type="email"
                            id="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            placeholder="Your email address"
                            required
                        />
                    </div>

                    <div className="form-group">
                        <label htmlFor="message"><BsFillChatLeftTextFill size={20} /></label>
                        <textarea
                            id="message"
                            name="message"
                            value={formData.message}
                            onChange={handleChange}
                            placeholder="Write your message here"
                            rows="5"
                            required
                        />
                    </div>

                    <button type="submit" className="submit-btn">Send Message</button>
                </form>

                {isSubmitted && (
                    <div className="submission-message">
                        <p>Thank you for reaching out! I will get back to you as soon as possible.</p>
                    </div>
                )}
            </div>
        </section>
    );
};

export default Contact;

import React from "react";
import { BsInfoCircleFill } from "react-icons/bs";
import Header from "../../components/header/header";

const Resume = () =>
{
    return(
        <section id="Resume" className="Resume">
            <Header HeaderText = "My Resume" Icon={<BsInfoCircleFill size={30}/>}/>

        </section>
    )
}

export default Resume;
import React from "react";
import { BsInfoCircleFill } from "react-icons/bs";
import Header from "../../components/header/header";
const Skill = () =>
{
    return(
        <section id="Skill" className="Skill">
            <Header HeaderText = "My Skills" Icon={<BsInfoCircleFill size={30}/>}/>

        </section>
    )
}

export default Skill;
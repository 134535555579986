import './App.scss';
import { Routes, Route } from 'react-router-dom';
import Particles from 'react-tsparticles';
import { loadFull } from 'tsparticles';
import particleOption from "./config/particles/particles"
import Home from './pages/home/home';
import About from './pages/about/about';
import Contact from './pages/contact/contact';
import Skills from './pages/skills/skills';
import Portfolio from './pages/portfolio/portfolio';
import Resume from './pages/resume/resume';
import Navbar from './components/navbar/navbar';


function App()
 {

  const particlesInit = async (main) =>
  {
    await loadFull(main);
  }

  return (
    <div className="App">
      {/* Particles */}
      <div className='particlesContainer'>
        <Particles id='tsparticles' init={particlesInit} options={particleOption}/>
      </div>
      
      {/* Navbar */}
      <Navbar />

      {/* Main Page Content */}
      <div className='App__MainpageContent'>
        <Routes>
          <Route path='/' element={< Home />} />
          <Route path='/About' element={< About />} />
          <Route path='/Contact' element={< Contact />} />
          <Route path='/Skills' element={< Skills />} />
          <Route path='/Portfolio' element={< Portfolio />} />
          <Route path='/Resume' element={< Resume />} />
        </Routes>
      </div>
    </div>
  );
}

export default App;

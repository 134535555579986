import React, { useState } from "react";
import { Link } from "react-router-dom";
import { HiX } from "react-icons/hi";
import { FaBars } from "react-icons/fa";
import Logo from "../../images/Logo.png";
import "./style.scss";

const pages = [
  { label: "Home", to: "/" },
  { label: "About", to: "/About" },
  { label: "Skills", to: "/Skills" },
  { label: "Portfolio", to: "/Portfolio" },
  { label: "Resume", to: "/Resume" },
  { label: "Contact", to: "/Contact" },
];

const Navbar = () => {
  const [toggleIcon, setToggleIcon] = useState(false);

  const handleToggleIcon = () => {
    setToggleIcon(!toggleIcon);
  };

  return (
    <nav className="Navbar">
      <div className="Navbar__Container">
        {/* Logo Section */}
        <Link to="/" className="Navbar__Container__Logo">
          <img src={Logo} alt="Logo" className="Navbar__Container__Logo__Img" />
        </Link>

        {/* Menu Section */}
        <ul className={`Navbar__Container__Menu ${toggleIcon ? "Active" : ""}`}>
          {pages.map((item, index) => (
            <li className="Navbar__Container__Menu__Item" key={index}>
              <Link
                className="Navbar__Container__Menu__Item__Link"
                to={item.to}
                onClick={() => setToggleIcon(false)} // Close menu on click
              >
                {item.label}
              </Link>
            </li>
          ))}
        </ul>

        {/* Hamburger Icon */}
        <div className="Navbar__Icon" onClick={handleToggleIcon}>
          {toggleIcon ? <HiX size={30} /> : <FaBars size={30} />}
        </div>
      </div>
    </nav>
  );
};

export default Navbar;

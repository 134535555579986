import React from "react";
import { BsInfoCircleFill } from "react-icons/bs";
import Header from "../../components/header/header";
const Portfolio = () =>
{
    return(
        <section id="Portfolio" className="Portfolio">
            <Header HeaderText = "Portfolio" Icon={<BsInfoCircleFill size={30}/>}/>

        </section>
    )
}

export default Portfolio;
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./style.scss";

const Home = () => {
  const roles = ["Game Developer", "Web Developer"];
  const [currentRole, setCurrentRole] = useState("");
  const [roleIndex, setRoleIndex] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);
  const [typingSpeed, setTypingSpeed] = useState(100);

  const navigate = useNavigate();

  useEffect(() => {
    const handleTyping = () => {
      const fullText = roles[roleIndex];
      if (isDeleting) {
        // Remove characters
        setCurrentRole((prev) => prev.slice(0, -1));
        setTypingSpeed(50); // Faster deleting speed
      } else {
        // Add characters
        setCurrentRole((prev) => fullText.slice(0, prev.length + 1));
        setTypingSpeed(100); // Normal typing speed
      }

      if (!isDeleting && currentRole === fullText) {
        // Pause before erasing
        setTimeout(() => setIsDeleting(true), 1000);
      } else if (isDeleting && currentRole === "") {
        // Move to the next role
        setIsDeleting(false);
        setRoleIndex((prev) => (prev + 1) % roles.length);
      }
    };

    const typingTimer = setTimeout(handleTyping, typingSpeed);
    return () => clearTimeout(typingTimer);
  }, [currentRole, isDeleting, typingSpeed, roles, roleIndex]);

  const handleNavigate = (path) => {
    navigate(path);
  };

  return (
    <section id="Home" className="Home">
      <div className="Home__TextWrapper">
        <h1>
          Hello, I'm Erick <br /> I'm a:{" "}
          <span className="typing">{currentRole}</span>
        </h1>
      </div>
      <div className="Home__Buttons">
        <button onClick={() => handleNavigate("/About")}>About Me</button>
        <button onClick={() => handleNavigate("/Contact")}>Hire Me</button>
      </div>
    </section>
  );
};

export default Home;
